(function ($) {
    $('#slider-0 .slides').slick({
        slidesToShow: 1,
        autoplay: true,
        arrows: false,
        dots: true,
        appendDots: $('#slider-0 .control'),
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        fade: true,
    });
    //Слайдеры в портфолио
    $('#slider-p1 .slides').slick({
        slidesToShow: 1,
        autoplay: true,
        arrows: false,
        dots: true,
        appendDots: $('#slider-p1 .control'),
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        fade: true,
    });
    $('#slider-p2 .slides').slick({
        slidesToShow: 1,
        autoplay: true,
        arrows: false,
        dots: true,
        appendDots: $('#slider-p2 .control'),
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        fade: true,
    });
    $('#slider-p3 .slides').slick({
        slidesToShow: 1,
        autoplay: true,
        arrows: false,
        dots: true,
        appendDots: $('#slider-p3 .control'),
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        fade: true,
    });
    $('#slider-p4 .slides').slick({
        slidesToShow: 1,
        autoplay: true,
        arrows: false,
        dots: true,
        appendDots: $('#slider-p4 .control'),
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        fade: true,
    });
    $('#slider-p5 .slides').slick({
        slidesToShow: 1,
        autoplay: true,
        arrows: false,
        dots: true,
        appendDots: $('#slider-p5 .control'),
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        fade: true,
    });
    $('#slider-p6 .slides').slick({
        slidesToShow: 1,
        autoplay: true,
        arrows: false,
        dots: true,
        appendDots: $('#slider-p6 .control'),
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        fade: true,
    });
    $('#slider-p7 .slides').slick({
        slidesToShow: 1,
        autoplay: true,
        arrows: false,
        dots: true,
        appendDots: $('#slider-p7 .control'),
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        fade: true,
    });
    $('#slider-p8 .slides').slick({
        slidesToShow: 1,
        autoplay: true,
        arrows: false,
        dots: true,
        appendDots: $('#slider-p8 .control'),
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        fade: true,
    });
    $('#slider-p9 .slides').slick({
        slidesToShow: 1,
        autoplay: true,
        arrows: false,
        dots: true,
        appendDots: $('#slider-p9 .control'),
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        fade: true,
    });
    $('#slider-p10 .slides').slick({
        slidesToShow: 1,
        autoplay: true,
        arrows: false,
        dots: true,
        appendDots: $('#slider-p10 .control'),
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        fade: true,
    });
    $('#slider-p11 .slides').slick({
        slidesToShow: 1,
        autoplay: true,
        arrows: false,
        dots: true,
        appendDots: $('#slider-p11 .control'),
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        fade: true,
    });
    //


    $('#slider-1 .slides').slick({
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 4,
        autoplay: true,
        arrows: false,
        dots: true,
        appendDots: $('#slider-1 .control'),
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    vertical: false,
                    verticalSwiping: false,
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToScroll: 3,
                    slidesToShow: 3,
                    vertical: false,
                    verticalSwiping: false,
                }
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToScroll: 2,
                    slidesToShow: 2,
                    vertical: false,
                    verticalSwiping: false,
                }
            },
    ],
    });
    $('#slider-3 .slides').slick({
        slidesToShow: 4,
        autoplay: true,
        arrows: false,
        dots: true,
        appendDots: $('#slider-3 .control'),
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToScroll: 3,
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToScroll: 2,
                    slidesToShow: 2,
                }
            },
        ],
    });
    $('#slider-4 .slides').slick({
        slidesToShow: 4,
        autoplay: true,
        arrows: false,
        dots: true,
        appendDots: $('#slider-4 .control'),
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToScroll: 3,
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToScroll: 2,
                    slidesToShow: 2,
                }
            },
        ],
    });
    $('#slider-2 .slides').slick({
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 2,
        autoplay: true,
        arrows: false,
        dots: true,
        appendDots: $('#slider-2 .control'),
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToScroll: 4,
                    slidesToShow: 4,
                    vertical: false,
                    verticalSwiping: false,
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToScroll: 3,
                    slidesToShow: 3,
                    vertical: false,
                    verticalSwiping: false,
                }
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToScroll: 2,
                    slidesToShow: 2,
                    vertical: false,
                    verticalSwiping: false,
                }
            },
        ],
    });


    $('header .mobile-menu-icon').on('click', function(e){
        e.preventDefault();
        if($(this).parents('body').hasClass('menu-open')){
            $(this).parents('body').removeClass('menu-open');
            $(this).unbind('mouseenter mouseleave')
        }else{
            $(this).parents('body').addClass('menu-open');
        }

    });
    $('.mobile-menu .btn-close').on('click', function(e){
        e.preventDefault();
        $(this).parents('body').removeClass('menu-open');
    });

    $('#modal-success button, #modal-error button').on('click',function(){
        $.fancybox.close();
    });
    //$.fancybox.defaults.modal = true;
    $('.btn-close').on('click', function(){
        $.fancybox.close();
    });


    $('#modal-success button, #modal-error button').on('click',function(){
        $.fancybox.close();
    });
    $('.modal-link').fancybox();
    $('.btn-close').on('click', function(){
        $.fancybox.close();
    });

    $('.portfolio-block .tab-btn').on('click', function(e){
        var id_content = $(this).data('id-content');

        $(this).parents('ul').find('.tab-btn.active').removeClass('active');

        $(this).parents('.top-block-content').find('.slider-tab.active').removeClass('active');

        $(this).parents('.portfolio-block').find('.portfolio-list.active').removeClass('active');

        $(this).addClass('active');

        $('.portfolio-block').find('.portfolio-list#'+id_content).addClass('active');
        $('.portfolio-block .top-block-content').find('.slider-tab#slider-p'+id_content).addClass('active');
        $('.portfolio-block .top-block-content .slider-tab.active .slides').slick('setPosition');

    });


    $('#modal-success .btn-modal-close, #modal-error .btn-modal-close').on('click',function(){
        $.fancybox.close();
    });
    $('.modal-link').fancybox();
    $('.btn-close').on('click', function(){
        $.fancybox.close();
    });


    $('.checkbox').on('click', function(){
        var $checkbox = $(this).find('input'),
            checked = $checkbox.prop('checked');

        $checkbox.prop('checked', !checked);
        $(this).toggleClass('checkbox-checked', !checked);

    });

    $('form').on('submit',function(e){
        e.preventDefault();
        /* var i=0;*/
        /* $(this).find('.required').each(function(){
             if(!$(this).find('input').val()){
                 $(this).addClass('error');
                 i++;
             }
         });
         if(i>0 || !$('.container-politics input').attr('checked')){
             return;
         }*/

        var form = new FormData(this);
        $.ajax({
            type: "POST",
            url: $(this).attr('action'),
            data: form,
            processData: false,
            contentType: false,
            success: function() {
               $.fancybox.close();
                console.log('успех');
                $.fancybox.open($('#modal-success'));
            } ,
            error:function(){
                console.log('ошибка')
                $.fancybox.open($('#modal-error'));
            }
        });
    });


}(jQuery));